<template>
	<a href="javascript:void(0)" @click="toDetail" :class="{'readed': data.is_read }">
        <div class="media">
            <div class="media-body">
                <div class="row justify-content-between">
                    <div class="col-7">
                        <h6 class="media-heading">{{ data.title }}</h6>
                    </div>

                    <div class="col-5 text-right">
                        <small>
                            <time class="media-meta" datetime="2015-06-11T18:29:20+08:00">{{ data.date }}</time>
                        </small>
                    </div>
                </div>
                <p class="notification-text">{{ data.desc }}</p>
            </div>
        </div>
    </a>
</template>

<script>
export default {
	name: 'Notification',
	props: ['data'],
    methods: {
        toDetail() {
            let _ = this
            _.axios.get('/notification/read/' + _.data.id)
                .then(resp => {
                    console.log(resp)
                    _.$emit('readNotif', _.data.id)
                    // _.$router.push('/kontrak/' + _.data.kontrak_id)
                    window.location = '/kontrak/' + _.data.kontrak_id
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }
}
</script>